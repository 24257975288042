import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
//import Button from '../../components/button/button';
import Carousel from '../../components/carousel/carousel';
import TeamCard from '../../components/team-card/teamCard';
import CustomLink from '../../components/custom-link/customLink';
//Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
//import TrainingIcon from '../../assets/icons/training.svg';
import Training from '../../assets/photos/training.jpg';
import PlaceholderImage from '../../assets/photos/team-1.png';
import Operations from '../../assets/photos/operations.jpg';

import CatieHeadshot from '../../assets/photos/team/cate-hart-static.png';
import DaKirahHeadshot from '../../assets/photos/team/dakirahJenkins.jpeg';
import RobynHeadshot from '../../assets/photos/team/Robyn.png';
import GloriaHeadshot from '../../assets/photos/team/gloria-ng-static.png';
import JakkiHeadshot from '../../assets/photos/team/jakkiBedsole.jpeg';
import LauraHeadshot from '../../assets/photos/team/laura-hackney-static.png';
import DawnHeadshot from '../../assets/photos/team/Dawn Allen.jpg';
import ChristinaHeadshot from '../../assets/photos/team/Christina Veasey.jpg';
import RavenHeadshot from '../../assets/photos/team/Raven.jpeg';
import JenHeadshot from '../../assets/photos/team/Jen.jpg';
//import LaurenHeadshot from '../../assets/photos/team/Lauren.jpg';
import AriannaHeadshot from '../../assets/photos/team/Arianna.jpg';
import HaniyaHeadshot from '../../assets/photos/team/Haniya.jpg';


import LawrenceHeadshot from '../../assets/photos/team/lawrenceNew.png';
import McKinleyHeadshot from '../../assets/photos/team/mck.jpeg';
import MaruHeadshot from  '../../assets/photos/team/maru copy.png';
import MinHeadshot from  '../../assets/photos/team/Min.jpeg';
import CarlaHeadshot from  '../../assets/photos/team/Carla.png';
import SarinaHeadshot from  '../../assets/photos/team/Sarina.jpeg';
import SteffiHeadshot from '../../assets/photos/team/Steffi.jpeg';
import NuriHeadshot from  '../../assets/photos/team/Nuri.jpeg';
import TanyaHeadshot from  '../../assets/photos/team/Tanya.jpeg';
import GinaHeadshot from '../../assets/photos/team/gina.jpg';
import NoniHeadshot from '../../assets/photos/team/noni.jpg';
import MoiraHeadshot from '../../assets/photos/team/moira.png';
import RichardHeadshot from '../../assets/photos/team/richard.jpg';
import AysegulHeadshot from '../../assets/photos/team/aysegul.jpg';
import MariaHeadshot from '../../assets/photos/team/maria.jpeg';
import SaxonHeadshot from '../../assets/photos/team/Saxon.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartBar,
	faHandsHelping,
} from '@fortawesome/free-solid-svg-icons';


const ourTeamCarouselSlides = [
	{
		image: Training,
		label: 'Training',
		description:
			'Our instruction team delivers more than 700 contact hours to support every survivor to succeed through their journey at AnnieCannons. They are not only training our next class of talented engineers, they are building a new pathways for individual to start careers in tech.',
		link: { label: '', url: '' },
	},
	{
		image: PlaceholderImage,
		label: 'Product and Engineering',
		description:
			'Our program, product, and engineering teams are made up of talented graduates and seasoned industry experts from startup to Fortune 500 companies. Together, they support participants and oversee client projects and internal technology development to bring product visions to life.',
		link: { label: '', url: '/' },
	},
	{
		image: Operations,
		label: 'Operations and Wellness',
		description:
			'Our operations team support the functions of AnnieCannons, our staff, participants, and supporters. This talented groups of individuals serve our mission and help AC reach even more survivors.',
		link: { label: '', url: '' },
	},
];



function OurTeam() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="ac-our-team">
			<Navigation selected='our purpose'></Navigation>
			<section className="overFlowHide ac-our-team-hero" aria-labelledby="hero-heading">
				<div className="ac-our-team-hero-container">
					<h1 className="ac-our-team-heading" id="hero-heading">
						Our Team
					</h1>
					<p className="desktopWidth45Percent ac-our-team-body">
						Our talented team includes individuals with experience in technology, education, product development, social work, and nonprofit success.
					</p>
				</div>
				<Star className="ac-our-team-hero-star"></Star>
				<Star className="ac-our-team-hero-star"></Star>
				<Star className="ac-our-team-hero-star"></Star>
			</section>
			<section
				className="ac-our-team-carousel-section"
				aria-labelledby="our-team-carousel-heading">
				<Star className="ac-our-team-carousel-star"></Star>
				<Star className="ac-our-team-carousel-star"></Star>

				<div className="ac-our-team-carousel-container">
					<Carousel slides={ourTeamCarouselSlides} variant="ourTeamCarousel"></Carousel>
				</div>

				<div
					className="ac-our-team-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-136px',
					}}></div>
			</section>
			<section
				className="ac-our-team-section"
				aria-labelledby="staff-heading">
				<div className="ac-our-team-staff-container">
					<h2 className="ac-our-team-teaching-subheading" id="staff-heading">
						Our Team
					</h2>
					<div className="ac-our-team-staff-content">
						<TeamCard
							imgSrc={LauraHeadshot}
							firstColor='teal'
							label='Circular headshot of Laura Hackney, CEO + Cofounder'
							name='Laura Hackney'
							title='CEO + Cofounder'
							link={{ label: '', url: 'https://www.linkedin.com/in/laura-hackney' }}
							bio="Laura Hackney is a human rights advocate and full-stack software engineer from Atlanta, GA. Laura helped to shape AnnieCannons’ Coding Training Program and develop AC’s internal portfolio of software products. Prior to AnnieCannons, Laura managed the Program on Human Rights at Stanford University’s Freeman Spogli Institute. She also worked as the Senior Research Associate for Stanford’s Anti-Trafficking Project in the Mekong Sub-Region and was a Program Associate for the Freeman Spogli Institute’s International Policy Implementation Lab. Laura is interested in the intersections of human rights, technology, and education. She serves on Alameda County' s AC United Steering Committee and supports the local Bay Area anti-trafficking groups. Laura gained her undergraduate degree from the University of California, Berkeley and her International Policy Masters from Stanford University." />
						<TeamCard
							imgSrc={JenHeadshot}
							firstColor='teal'
							label='Circular headshot of Jen Kramer, Director of Learning and Technology'
							name='Jen Kramer'
							title='Director of Learning and Technology'
							link={{ label: '', url: 'https://www.linkedin.com/in/jen4web/' }}
							bio="For over twenty years, Jen Kramer has been educating clients, colleagues, friends and graduate students about the meaning of a “quality website.” An award-winning instructor, Jen has taught web design, educational technology, and storytelling principles to students from Harvard University and other academic institutions, and students learning in conferences, workshops, and corporate environments. Jen is a prolific video author, creating over 70 training courses for LinkedIn Learning, Frontend Masters. and others. Jen’s most recent book is 'Before You Code: Validate Your Idea, Build a Better Product, and Plan Your Way to Success', co-authored with Heather O’Neill. Previous titles include 'Joomla Start to Finish' (2010) and 'Joomla 24-Hour Trainer' (2011). Jen earned a BS in biology at University of North Carolina at Chapel Hill, a MS in Internet Strategy Management at the Marlboro College Graduate School, and a graduate certificate in Learning Design and Technology from Harvard University Extension School." />
						<TeamCard
							imgSrc={RavenHeadshot}
							firstColor='teal'
							label='Circular headshot of Raven Collins, Director of Development'
							name='Raven Collins'
							title='Director of Development'
							link={{ label: '', url: 'https://www.linkedin.com/in/raven-collins-24877265/' }}
							bio="Raven Collins is a Georgia native and graduate of Clark Atlanta University. She is a proud veteran of the United States Army, and now finds purpose in selflessly serving and being a voice for others. Raven has navigated the non-profit arena for 7 years and excelled in Development, bringing innovation, strategy, and campaign successes to the fold. She leads as a value-creating professional with progressively responsible experience emphasizing donor relations, fundraising initiatives, planning, and event management. Raven has established collaborative and viable relationships with partners and donors at all levels, which has allowed her to thrive as a trusted advocate, championing engaging and power-driven fundraising. " />

					
						<TeamCard
							imgSrc={DaKirahHeadshot}
							firstColor='red'
							label="Circular headshot of Da'Kirah Jenkins, Engineering Manager"
							name="Da'Kirah Jenkins"
							title='Engineering Manager'
							link={{ label: '', url: 'https://www.linkedin.com/in/dakirahjenkins/' }}
							bio="Da'Kirah Jenkins, an Engieering Manager with AnnieCannons, brings 5+ years of experience with HTML5, CSS3, JavaScript, ES6, React, React Native, Vue.js, and Wordpress. Da'Kirah brings qualified technical expertise to building responsive websites, web apps, and mobile apps for a range of businesses, including tech startups, non-profits, and established industry leaders. In addition to her development work, she also oversees teams of developers within AnnieCannons." />
							<TeamCard
							imgSrc={CatieHeadshot}
							firstColor='teal'
							label='Circular headshot of Catie Hart, Teaching Engineer'
							name='Catie Hart'
							title='Teaching Engineer'
							link={{ label: '', url: 'https://www.linkedin.com/in/catiehart' }}
							bio="Catie Hart currently serves as a Lecturer at AnnieCannons and holds a Bachelor’s degree in Sociology from the University of California, Berkeley. Catie began her teaching career leading workshops for service providers and activists in the anti-trafficking space, helping these students to cut through the noise and access resources that empower and engage using tangible methods and tools. She is often recognized for her work as a survivor leader as well as a trainer. Catie’s deep experience with the fight to both understand and end human trafficking adds a material layer of trauma-informed curriculum management to AnnieCannons' classroom." />
						
						<TeamCard
							imgSrc={GloriaHeadshot}
							firstColor='teal'
							label='Circular headshot of Gloria Ng, Engineering Manager'
							name='Gloria Ng'
							title='Engineering Manager'
							link={{ label: '', url: 'http://linkedin.com/in/fengshuigal' }}
							bio='Gloria Ng currently serves as a Engineering Manager at AnnieCannons. Gloria has spent several years teaching youth in the Bay Area, including severely disadvantaged youth with special needs. Her empathy in helping those who have faced adversity to learn is complemented by sharp technical skills and an incredible work ethic. Together, these qualities make Gloria a key element in the growth of
							 AnnieCannons’ training program.' />
						<TeamCard
							imgSrc={JakkiHeadshot}
							firstColor='red'
							label='Circular headshot of Jakki Bedsole, Product Manager'
							name='Jakki Bedsole'
							title='Product Manager'
							link={{ label: '', url: 'https://www.linkedin.com/in/jaclynbedsole/' }}
							bio="Jakki Bedsole is AnnieCannons' Product Manager for ResourceFull, a mobile platform for survivors of trafficking dedicated to generating exit-ramps from exploitation. As an activist in the local and national anti-trafficking movement, Jakki has served in a multitude of roles. She has been a direct service provider working with vulnerable populations, a researcher and writer speaking to the prevalence of and disparities among domestic sex trafficking survivors, a public speaker and trainer educating communities about trafficking and gender-based violence and a nonprofit leader overseeing program delivery and leading in the areas of program, organizational, fund and team development. Jakki holds a B.A. in Sociology, a Masters of Social Work (MSW) and is a front-end software engineer dedicated to building power among marginalized communities through the development of social impact technology." />
						<TeamCard
							imgSrc={ChristinaHeadshot}
							firstColor='red'
							label='Circular headshot of Christina Veasey, Product Manager'
							name='Christina Veasey'
							title='Product Manager'
							link={{ label: '', url: 'https://www.linkedin.com/in/christina-veasey-217367162/' }}
							bio="Christina, or ChrissyVC, is a Product Manager at AC. Christina began her career at AnnieCannons as a Research Assistant on ResourceFull, then transitioned to a Research Manager and then to her current position as Product Manager. As a product manager, Christina manages multiple projects. She is passionate about social impact technology and working towards cultivating change with tech. She is also a mother of four, and has varied interests that include coffee, spirituality and cooking." />
							{/* <TeamCard
							imgSrc={IramHeadshot}
							firstColor='red'
							label='Circular headshot of Iram Shaikh, ResourceFull UX Research & Partnerships Coordinator'
							name='Iram Shaikh'
							title='ResourceFull UX Research & Partnerships Coordinator'
							link={{ label: '', url: 'https://www.linkedin.com/in/shaikhiram/' }}
							bio="Iram is AnnieCannons’ UX Researcher and Partnerships Coordinator for the ResourceFull app, a mobile platform for survivors of trafficking dedicated to generating exit-ramps from exploitation. With a background in education and business analysis, Iram has extensive experience cultivating partnerships with stakeholders, working in agile environments and using strategic thinking to inform her work. Iram currently uses qualitative and quantitative research to inform product design, strategy and goals, helping to unblock and inspire product teams to build thoughtfully. She is passionate about designing experiences informed by data and driven by empathy. She also manages and maintains all nonprofit partners to the app, helping them make successful connections with people using the platform." /> */}
						 <TeamCard
							imgSrc={DawnHeadshot}
							firstColor='red'
							label='Circular headshot of Dawn Allen, Career Development Manager'
							name='Dawn Allen'
							title='Career Development Manager'
							link={{ label: '', url: 'https://www.linkedin.com/in/dawn-allen-2018/' }}
							bio="Dawn embraces a collective community approach through a healing-centered perspective and supports people in realizing and accomplishing their goals. She is a former program manager for Codetalk Technology Vocational Program and an advocate for survivors, facilitating live learning sessions focused on increasing awareness about human trafficking with United Way and Rising Worldwide. Dawn’s emphasis is in leveraging technology to empower people from disadvantaged backgrounds. She is the proud and happy mother of two young adults and a dog." />
						
						<TeamCard
							imgSrc={RobynHeadshot}
							firstColor='teal'
							label='Circular headshot of Robyn Borders, Student Success Manager'
							name='Robyn Borders'
							title='Student Success Manager'
							link={{ label: '', url: 'https://www.linkedin.com/in/robyn-borders-b76383164' }}
							bio="Robyn Borders is a Human Services Professional and Counselor at heart.  She consistently strives to be a safe space where individuals can share life’s challenges and explore options for increasing self awareness, engaging in skill development, and working towards mental and emotional wellness. 

							Her passion for supporting others has manifested into a 14 year career serving in various roles in the areas of disability services and advocacy, family services, post-secondary education, and child welfare. Robyn holds a Bachelor of Science Degree in Psychology and a Master of Science degree in Mental Health Counseling. Robyn is also the founder of Moment By Moment Counseling Services in Orlando, Florida.
							
							Beach days, RnB music and french fries are a few things that bring joy into Robyn’s life. While her professional roles are extremely important, the role of “TT” to her niece and Godmother to her two Goddaughters are her most valued titles to date. 
							" />
							
						<TeamCard
							imgSrc={AriannaHeadshot}
							firstColor='teal'
							label='Circular headshot of Arianna Ninh, Coding Instructor'
							name='Arianna Ninh'
							title='Coding Instructor'
							link={{ label: '', url: 'https://www.linkedin.com/in/ariannaninh/' }}
							bio="Arianna Ninh is an educator and lifelong learner based in the San Francisco Bay Area. A maker at heart, Arianna’s journey to teaching coalesced through her love for tinkering and being part of a curious, creative community. She first learned to build and break things with code while studying at the University of California, Berkeley. Her teaching career has taken her many places from leading digital fabrication workshops at makerspaces to her most recent role as Senior Creative Technologist at SuperHi, an online platform for learning web development and design. There, she oversaw technical educational support and built tutorials and learning resources for a vibrant student community. She is incredibly honored and excited to join Annie Cannons in helping people embrace the messy magic of learning to code, build portfolios they’re proud of, and find sustainable careers in tech." />
					
						<TeamCard
							imgSrc={HaniyaHeadshot}
							firstColor='teal'
							label='Circular headshot of Haniya Ghauri, Associate Coding Instructor'
							name='Haniya Ghauri'
							title='Associate Coding Instructor'
							link={{ label: '', url: 'https://www.linkedin.com/in/haniya-ghauri' }}
							bio="Haniya is a dedicated designer and an educator, on a mission to empower others through sharing knowledge, particularly in the fields of technology and design. Early in Haniya's academic journey, she nurtured a deep love for technology which fuels her drive to integrate education, technology, and design to make a positive impact in the world. She is committed to bridging the gender gap in tech and has actively contributed to this cause through initiatives like Girls Who Code, volunteering her time to teach coding and introduce young girls to careers in technology, empowering them to pursue their interests and break barriers in the industry. Beginning her journey at AnnieCannons as a UX/UI Design intern, Haniya has seamlessly transitioned into her current role as an Associate Coding Instructor. With a Bachelors in Computer Science and extensive design and teaching experience, she blends her creative vision with educational leadership to inspire continual learning and growth." /> 
					{/* </div>
					<h2 className="ac-our-team-leadership-subheading ac-our-team-staff-separator" id="staff-heading">
						Leadership
							</h2>
					<div className="ac-our-team-staff-content"> */}
						
					</div>
				</div>
			</section>
			<section
				className="ac-our-team-carousel-section"
				aria-labelledby="staff-heading" id="board">
				<div className="ac-our-team-staff-container">
					<h2 className="ac-our-team-teaching-subheading" id="staff-heading">
						Our Board
					</h2>
					<div className="ac-our-team-staff-content">
						<TeamCard
							imgSrc={LawrenceHeadshot}
							firstColor='red'
							label='Circular headshot of Lawrence Cole, Board Chair'
							name='Lawrence Cole'
							title='Director - Board Chair'
							link={{ label: '', url: 'https://www.linkedin.com/in/lawrencecole/' }}
							bio="Lawrence Cole is a B2B Go-to-Market leader residing in San Jose, CA with his wife and son. Initially educated as an electrical and software engineer, he has built a rewarding career leading revenue-driving functions within the SaaS subcategory of AdTech/MarTech at Google and Amazon. His superpower is creating and activating the infrastructure for scaling revenue (strategy, people, process, and systems) to meet and exceed CEO and board objectives while optimizing human capital (culture, performance, DEI) for maximum returns. He also advises startups who are planning, building, and scaling their sales and revenue operations.

							Lawrence is the benefactor of the Lawrence L Cole and Family Endowed Scholarship at Florida A&M University and gives to a number of educational causes through The Cole Family Foundation. Lawrence holds a bachelor of science in electrical engineering, a masters in industrial-organizational psychology from Harvard University and an MBA from The Wharton School at the University of Pennsylvania.
							" />
						<TeamCard
							imgSrc={McKinleyHeadshot}
							firstColor='teal'
							label='Circular headshot of McKinley Smith'
							name='McKinley Smith'
							title='Director - Board Secretary'
							link={{ label: '', url: 'https://www.linkedin.com/in/mckinleyhindi/' }}
							bio='McKinley is the Head of Planning and Strategy for biotechnology company, Checkerspot. Prior to this, she led a $180M portfolio at Genentech across Product Development Informatics where she facilitated improved information flow across the product development lifecycle, patient and investigator and health authority engagement, and enabled increased development through sustainable, smart technology-enabled strategy.Before moving to biotech, she led strategy, operations, and communications across Pharma, Media, Global Public Relations, and Marketing organizations. McKinley was a founding board member with AnnieCannons. She was a member of the Fall 2020 Get Sh!t Done cohort as the founder of the Anita Project, focused on improving workplace harassment documentation. She was the co-founder of Graduate Washington, a nonpartisan political action committee focused on improving higher education access in Washington state, and has served on the board for the Hong Kong Business Association of Washington, and served as spokesperson for the National Eating Disordered Association.  She graduated from the University of Washington and splits her time between Portland, OR and Brooklyn, NY.' />
						<TeamCard
							imgSrc={MinHeadshot}
							firstColor='red'
							label='Circular headshot of Min Matson'
							name='Min Matson'
							title='Board Treasurer'
							link={{ label: '', url: 'https://www.linkedin.com/in/minmatson/' }}
							bio="Min Matson is a director with PwC’s DEI Consulting practice where he focuses on helping organizations embed diversity, equity, and inclusion into their organizational strategy and culture by driving inclusive leadership behaviors, leveraging data and technology to influence action, and building sustainable momentum for transforming the future for underrepresented and historically marginalized communities.
							" /> 
						<TeamCard
							imgSrc={SarinaHeadshot}
							firstColor='red'
							label='Circular headshot of Sarina Beges-Thysen'
							name='Sarina Beges-Thysen'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/sarinabeges/' }}
							bio= "Sarina Beges-Thysen is a senior leader working at the intersection of philanthropy and social impact. At present, she serves as the associate director of the Aspen Institute's Program on Philanthropy and Social Innovation where she convenes and curates the Aspen Philanthropy Group, a community of CEOs from leading private and public foundations in the US. For 15 years, Sarina has partnered with foundations, non-profit leaders, tech, government, academic institutions, and philanthropists to launch high-impact programs. Throughout her career, Sarina has leveraged technology to empower social sector leaders with innovative content, connection, and capital. She designed grant-making strategies to fund community leaders across the Middle East and North Africa, built global education programs for human rights leaders and democracy defenders around the world, developed philanthropic plans for high-net worth individuals, and launched an initiative to host social entrepreneurs at Stanford. She also brings an expertise in leading high-performing teams and spearheading organizational development initiatives to create resilient, adaptive, and sustainable institutions." /> 
						<TeamCard
							imgSrc={MaruHeadshot}
							firstColor='red'
							label='Circular headshot of Maru Colbert'
							name='Dr. Maru Colbert'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/maru-colbert-127b9b14/' }}
							bio="Dr. Maru Colbert is a professor in the sciences and a practitioner in the arts. Postdoctoral and international field studies led her to combine chemical, environmental and materials engineering. Past research has focused on phytoremediation, micro-scale processes, climate science/atmospheric modeling, surface chemistry, NOx sensing, and pre-engineering education. She and her research teams are at universities in the US and abroad (Australia, Canada, the UK, China, and Africa). Her performances extend to plays, concerts and poetry slams nationwide. Professor Colbert has received commendations for both her teaching and artistry. Her STEM work is related to her profession and passion: providing resources to counter the lack of proficiency in STEM subjects due to inequity in education. She leads or contributes to STE(A)M initiatives in 4 cities in the U.S. and 3 countries in Africa.  Dr. Colbert advises AnnieCannons on general board matters, curriculum, connections for organizational growth and specific actions to better serve less-privileged populations.
							" />
						<TeamCard
							imgSrc={NuriHeadshot}
							firstColor='red'
							label='Circular headshot of Nuri Djavit'
							name='Nuri Djavit'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/nuridjavit/' }}
							bio="Nuri Djavit is the Chief Marketing Officer at Singularity University. With over 20 years experience starting and building exciting and resilient companies, Nuri is a strategic leader with demonstrated success in recognizing and maximizing individual and cross-functional team strengths positively impacting culture, productivity and customer value. Nuri raised capital, developed strategic partnerships, licensing, acquisitions and exits. He has expertise in Corporate/strategic development, team building and leadership, KPI/goal setting and management, budgeting and P&L, brand strategy & experience development, digital transformation, growth and marketing." /> 
						
					    <TeamCard
							imgSrc={CarlaHeadshot}
							firstColor='red'
							label='Circular headshot of Carla H. McIntosh'
							name='Carla H. McIntosh'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/carlahmcintosh/' }}
							bio="Carla H. McIntosh is VP of Global Talent Acquisition at Reddit where she is responsible for strengthening and scaling operational processes for its global workforce. With more than 24 years of experience leading talent organizations, Carla brings to Reddit a genuine passion for people and a proven record of building world-class recruiting practices and teams. She most recently served as the U.S. Head of Talent Acquisition for Google’s first-ever U.S. Operations Center and also previously held several roles across People Operations. Carla is also a U.S. Air Force veteran and was the founder of a technical recruitment firm. 
							" /> 
						<TeamCard
							imgSrc={SteffiHeadshot}
							firstColor='red'
							label='Circular headshot of Stephanie Swartz'
							name='Stephanie Swartz'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/steffibryson/' }}
							bio='Stephanie Swartz is the Vice President of Policy and Public Affairs for The Pill Club’s, where she advocates for access to affordable, convenient, and high quality healthcare for women and people who menstruate. Previously, Stephanie worked in global public policy roles at Google and Uber, covering consumer protection topics ranging from privacy to safety. At Uber, she led efforts to publish the company’s first of its kind Safety Report, which detailed incidents including sexual assaults on the platform. Stephanie graduated summa cum laude and Phi Beta Kappa from California State University Long Beach and received an MPhil in International Relations from Oxford University, where she was a Rhodes Scholar. A native of Southern California, Stephanie currently lives in Washington, D.C.' />
						<TeamCard
							imgSrc={TanyaHeadshot}
							firstColor='red'
							label='Circular headshot of Tanya Thomas'
							name='Tanya Thomas'
							title='Director'
							link={{ label: '', url: 'https://www.linkedin.com/in/tanya-m-thomas/' }}
							bio= "As a graduate of AnnieCannons’ first virtual cohort and internship program, Tanya Thomas is extremely happy to join the board. As a lived experience expert in the CSEC (Commercial Exploitation of Children) form of human trafficking and active in the multinational anti-trafficking movement, she hopes to further guide AC in providing such an amazing opportunity to fellow survivors." /> 
					
						
					
					{/* </div>
					<h2 className="ac-our-team-leadership-subheading ac-our-team-staff-separator" id="staff-heading">
						Leadership
							</h2>
					<div className="ac-our-team-staff-content"> */}
						
					</div>
				</div>
			</section>
			<section
				className="ac-our-team-carousel-section"
				aria-labelledby="staff-heading" id="board">
				<div className="ac-our-team-staff-container">
					<h2 className="ac-our-team-teaching-subheading" id="staff-heading">
						Our Advisors
					</h2>
					<div className="ac-our-team-staff-content">
						<TeamCard
							imgSrc={NoniHeadshot}
							firstColor='red'
							label='Circular headshot of Noni Allwood'
							name='Noni Allwood'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/noni-allwood-b35507/' }}
							bio="Noni’s career spans as an executive, entrepreneur and innovator. Her corporate career includes senior roles for Fortune 500 companies, including Cisco, Tandem Computers, and Exxon Corporation. As an entrepreneur and consultant, she is founder of Noni Allwood & Associates. In the area of Diversity and Inclusion, Ms.Allwood is a Senior Fellow & Senior Advisor to the Center for Talent Innovation." /> 
						<TeamCard
							imgSrc={MoiraHeadshot}
							firstColor='red'
							label='Circular headshot of Moira Amado-McCoy'
							name='Dr. Moira Amado-McCoy'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/moira-amado-mccoy/' }}
							bio="Moira Amado-McCoy is a Principal and Managing Director at Robertson Stephens and is responsible for comprehensively managing her clients’ wealth and executing their financial objectives. She aims to transform the wealth management experience from a static, cookie-cutter, volume-based enterprise into an agile, tailored, and intention-based service. Moira runs a woman-centered practice specializing in investing for social and political good, with a particular interest in growth investing for philanthropic purposes.  As a second-career professional, Dr. Amado-McCoy employs her understanding of power intersections to inform her stewardship advice for women and women’s advocates. Driven by commitment and compassion, and supported by a seasoned team of financial professionals representing various relevant specialties, her office offers bespoke solutions to help ensure long term financial success for clients and the issues that matter to them.  A strategy-driven approach for managing money explicitly acknowledges that accumulation is never the sole objective for earned and inherited wealth. Partnering with you and your other trusted advisors, we develop a comprehensive view of your financial goals which can then be effectively mapped onto the market economy. Together, we work to achieve not only financial health, but financial power." />  
						<TeamCard
							imgSrc={SaxonHeadshot}
							firstColor='red'
							label='Circular headshot of Saxon H. Knight'
							name='Saxon H. Knight'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/saxonhknight/' }}
							bio= "Saxon is an experienced global conference speaker and advisory board member, and currently serves as the Head of Strategy & Partnerships for Reality Defender, a deepfake detection and protection platform. Most recently, Saxon was the Director of Risk Intelligence for Meta/Facebook, charged with building a new Risk Intelligence organization to articulate a holistic risk picture across the Facebook family of apps and services. Saxon is also the Founder of House of Saxons, an organization dedicated to empowering sex trafficking survivors through education in essential and marketable business and life skills. Prior to Meta, Saxon was the Director of Threat Prevention & Defence for LinkedIn Trust & Safety, a Vice President for the Risk & Financial Advisory Practice at Deloitte US, and a Director for Deloitte UK in London. Saxon's background within private industry (Target Corporation and BNY Mellon) and the U.S. government (U.S. Department of State, U.S. Department of Homeland Security, U.S. Department of Energy) focused on key areas to include cyber threat intelligence, energy security, counterterrorism, critical infrastructure protection, cyber risk strategy, enterprise cyber program development and operational risk mitigation."/> 
						<TeamCard
							imgSrc={RichardHeadshot}
							firstColor='red'
							label='Circular headshot of Richard Lui'
							name='Richard Lui'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/richardlui/' }}
							bio= "Richard is a journalist and news anchor for MSNBC and NBC News and formerly CNN Worldwide. His international reporting has focused on humanitarian issues including gender equality and human trafficking. He has been designated a public representative for women’s and girl’s’ rights at the UN, Plan International, and Not For Sale." /> 
						<TeamCard
							imgSrc={MariaHeadshot}
							firstColor='red'
							label='Circular headshot of Maria Rosati'
							name='Maria Rosati'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/maria-rosati/' }}
							bio= "Maria is the Founder and CEO of Eminence Communications, a boutique brand strategy and marketing firm. Being the ultimate optimist, she sees opportunities where others see challenges. Known for challenging convention and never taking “NO” for an answer, she empowers change makers with the resources needed to effect change making a lasting impact, by designing highly curated omni-channel marketing programs showcasing her client’s unique perspectives and thought leadership. Acting as trusted counsel in building and maintaining client’s reputations, Eminence has a proven track record in developing and implementing strategic marketing programs that converts leads to loyal customers accelerating growth and revenue. Her career as a global marketing executive spans three decades working nationally and internationally on both the agency side and in-house with large, multinational financial service brands." /> 
						<TeamCard
							imgSrc={GinaHeadshot}
							firstColor='red'
							label='Circular headshot of Gina Sanders'
							name='Gina Sanders'
							title='Advisor'
							link={{ label: '', url: 'https://ginasanders.com/' }}
							bio="Gina Sanders is Founder and CEO of Gina's Collective, an Advisory providing coaching,connections and creativity to tech-enabled nonprofit startups. She also serves as a Venture Partner at independent venture capital firm Advance Venture Partners, a subsidiary of Advance Publications, Inc; as an Advisor to Y Combinator’s nonprofit startups, and to Condé Nast. From 2010-2014, Ms. Sanders was President and Chief Executive Officer of Fairchild Fashion Media." /> 
						<TeamCard
							imgSrc={AysegulHeadshot}
							firstColor='red'
							label='Circular headshot of Aysegul Yonet'
							name='Aysegul Yonet'
							title='Advisor'
							link={{ label: '', url: 'https://www.linkedin.com/in/aysegulyonet/' }}
							bio= "Aysegul is a Sr. Software Engineer at Autodesk with a passion for data visualization and analytics. Aysegul is active in various organizations working to train women and less privileged groups in coding, and has worked in full-time roles at software companies large and small. She teaches workshops for D3.js, AngularJS, and WebQL." /> 
					
						
					
					{/* </div>
					<h2 className="ac-our-team-leadership-subheading ac-our-team-staff-separator" id="staff-heading">
						Leadership
							</h2>
					<div className="ac-our-team-staff-content"> */}
						
					</div>
				</div>
			</section>
			<section
				className="ac-our-team-multiply"
				aria-labelledby="multiply-heading">
				<div className="ac-our-team-multiply-container">
					<h2 className="ac-our-team-multiply-subheading" id="multiply-heading">
						We multiply our power
					</h2>
					<div className="ac-our-team-multiply-content">
						<div>
							<FontAwesomeIcon icon={faChartBar} className="ac-our-team-multiply-icons" />
							<p className="ac-our-team-multiply-body">
								We help survivors to succeed in various ways.
							</p>
							<br />
							<CustomLink
								to='/our-purpose/our-mission'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="View our mission"
								internal
								className="ac-our-team-multiply-button"></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHandsHelping} className="ac-our-team-multiply-icons" />
							<p className="ac-our-team-multiply-body">
								We invite you to apply for any open positions and join our team!
							</p>
							<CustomLink
								to='/about/our-careers'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="View Openings"
								internal
								className="ac-our-team-multiply-button"></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default OurTeam;