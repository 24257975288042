import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
// Assets
import Mission1 from '../../assets/photos/mission-1.png';
import Wave3White from '../../assets/waves/wave-3-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	//faChartBar,
	faUsers
} from '@fortawesome/free-solid-svg-icons';


//import FEJobTestPdf from '../../assets/jobPdfs/CodingInstructor.pdf' // Import job pdf's like a component
import instructorPdf from '../../assets/jobPdfs/AssociateCoding.pdf' // Import job pdf's like a component

const jobs = [ // Add job object here with a title and a pdfUrl variable after importing the pdf above
	{ title: 'Associate Coding Instructor', pdfUrl: instructorPdf },
	//{ title: 'Director of Learning and Technology', pdfUrl: directorPdf },
	// { title: 'Back End Developer', pdfUrl: FEJobTestPdf },
]

const Careers = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-careers">
			<Navigation selected='our services'></Navigation>
			<section className="overFlowHide ac-careers-hero" aria-labelledby="hero-heading">
				<div className="ac-careers-hero-container">
					<h1 className="ac-careers-heading" id="hero-heading">Careers at AnnieCannons</h1>
					<p className="desktopWidth45Percent ac-careers-text">AnnieCannons’ employees tackle one of the world’s most vexing challenges - preventing human trafficking and supporting survivors - with passion, grit, and tremendous empathy.</p>
				</div>
				<Star className="ac-careers-hero-star"></Star>
				<Star className="ac-careers-hero-star"></Star>
				<Star className="ac-careers-hero-star"></Star>
			</section>
			<section
				className="ac-careers-jobs-section"
				aria-labelledby="jobs-heading">
				<div className="ac-careers-jobs-section-container">
					<div className="ac-careers-jobs-content">
						<div>
							<h2 className="ac-our-mission-subheading" id="engineers-heading">Current Job Opportunities</h2>
							<div className='flexColumn'>
								{jobs.length < 1 ?
									<p className="ac-careers-body">Thank you for your interest! There are currently no positions available.</p>
									:

									jobs.map((job) => {
										return(
											<a href={job.pdfUrl} className='jobLinks' target='_blank' rel='noopener noreferrer'>{job.title}</a>
										)
									})
								}
							</div>
						</div>
						<img src={Mission1} alt="Stary night computer with hand"></img>
					</div>
				</div>

				<div
					className="ac-careers-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			<section
				className="ac-careers-moreInfo"
				aria-labelledby="moreInfo-heading">
				<div className="ac-careers-moreInfo-container">
					<h2 className="marginBottom40 ac-careers-subheading moreInfo" id="moreInfo-heading">
						Learn more about us
					</h2>
					<div className="ac-careers-moreInfo-content">
						<div>
							<FontAwesomeIcon icon={faUsers} className="ac-our-mission-learn-more-icons" />
							<p className="ac-our-mission-learn-more-body">
								Our team is diverse, brilliant and dedicated to supporting others. 							</p>
							<CustomLink
								to='/about/our-team'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Meet our team"
								internal
								className="ac-our-mission-learn-more-button"></CustomLink>
						</div>
						{/* <div>
							<FontAwesomeIcon icon={faChartBar} className="ac-our-mission-learn-more-icons" />
							<p className="ac-our-mission-learn-more-body">
								We have specific ways of measuring the impact of our organization.
							</p>
							<CustomLink
								to='/our-purpose/our-impact'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="View our Impact"
								internal
								className="ac-our-mission-learn-more-button"></CustomLink>
						</div> */}
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default Careers;
